
.rbc-time-view .rbc-label {
  display: none;
  overflow-y: scroll;

}
.rbc-time-view .rbc-allday-cell {
  height: 55vh;
  max-height: unset;
  scroll-behavior: auto; 
  overflow-y: scroll;
}
.rbc-time-view .rbc-time-content {
  display: none;
  overflow-y: scroll;

}
.rbc-time-view-resources .rbc-time-header{
  overflow-x: scroll;
}
.rbc-off-range-bg{
  background: transparent !important;
}
.rbc-today{
  background: #2e2e30 !important;
}
.rbc-allday-cell{
  height:100% !important;
}

.rbc-time-view{
  flex: 0 !important;
}
.rbc-calendar{
  position: absolute !important;
  width:83% !important;
}
.rbc-event{
  background-color: #ec8d71 !important;
  color:#000;
}
.rbc-event-content{
  font-weight: 400 !important;
}
.rbc-time-view .rbc-row{
  padding: 5px;
}
.rbc-header{
  border: none ;
}

.container {
  height: 84vh;
  width: 350px;
  overflow-y: auto;
  
  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* hide scrollbar for chrome, safari and opera */
.container::-webkit-scrollbar {
  display: none;
}


.__markerjs2_{
  z-index: 9999999 !important;
  width: cover !important;
  position: fixed !important;
  top: 0 !important;
  left: 35% !important;
  box-shadow: 0 0 0 9999px black !important;
  background-color: black;
}
.__markerjs2_ img{
  margin-top:0 !important;
}
@media screen and (max-width:720px) {
  .__markerjs2_{
    z-index: 999999 !important;
    width: cover !important;
    top: 0 !important;
    left:15% !important;

  }
}

.rd3t-label__title {
  fill: #fff !important;
  stroke: none;
  font-weight: bolder;
}
.rd3t-node {
  cursor: pointer;
  fill: #fff !important;
  stroke: #000;
  stroke-width: 2;
}
.rd3t-link {
  fill: none;
  stroke: rgb(0, 179, 110);
}
.rd3t-link {
  fill: none;
  stroke: #fdbaff !important;
}

body {
  margin: 0;
  font-family: -apple-system,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:white;
  overflow:hidden
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar{
  width: 10px;
  height: 10px;
  background-color: #424242;
}

::-webkit-scrollbar-track{
  box-shadow: inset 0 0 5 px #000000;
  border-radius:  0px;
}

::-webkit-scrollbar-thumb{
  background: #686868;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover{
  background: #444444;
}


.MuiTablePagination-displayedRows .css-levciy-MuiTablePagination-displayedRows{
  color:white !important;
}





.tree-component {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  background-color: #1d1d1d;
  color: #f2f2f2;
  padding: 20px;
  max-height: 50vh;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
}
.tree-component-list{
  list-style-type: none !important;

}
.tree-component-item{
  padding:5px;

}
.tree-component-toggle{
  margin-right: 5px;
}


/* th {
  position: relative;
}

th:before,
th:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10px;
  pointer-events: none;
}

th:before {
  left: -10px;
  background-color: rgba(0, 0, 0, 0.1);
}

th:after {
  right: -10px;
  background-color: rgba(0, 0, 0, 0.1);
}

th:hover:before,
th:hover:after {
  background-color: rgba(0, 0, 0, 0.3);
} */